
















import { Component, Vue } from "vue-property-decorator";

@Component
export default class Features extends Vue {
  items = [
    {
      icon: require("@/assets/icon-elearning.svg"),
      title: "Interactive e-Lessons",
      text:
        "Enjoy online learning with interactive e-lessons enriched with text, audio, video and animations."
    },
    {
      icon: require("@/assets/icon-award.svg"),
      title: "Certificate of Participation",
      text:
        "For each course you successfully complete, get a special and verifiable certificate of attendance that is issued in your name."
    },
    {
      icon: require("@/assets/icon-videoconference.svg"),
      title: "Digital Citizen Identity",
      text:
        "Obtain a digital citizenship ID approved by ISOC-TR and The Media Literacy Association after all courses completed successfully."
    }
  ];
}
