















import { Component, Vue } from "vue-property-decorator";
import Banner from "./Banner.vue";
import Features from "./Features.vue";
import FeaturedCourses from "./FeaturedCourses.vue";
import About from "./About.vue";

@Component({
  metaInfo: {
    title: "Welcome!"
  },
  components: {
    Banner,
    Features,
    FeaturedCourses,
    About
  }
})
export default class HomeView extends Vue {}
